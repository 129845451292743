import React from "react";
import {
  Container,
  Button,
  // Customizable Area Start
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Hidden,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Paper,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogContent,
  Popper,
  InputAdornment
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import DescriptionIcon from '@material-ui/icons/Description';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import StorageProvider from "framework/src/StorageProvider.web";
import CreateNewPost from './CreateNewPost.web'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import { searchImage } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import AttachmentIcon from '@material-ui/icons/Attachment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const theme = createTheme({
  palette: {
    primary: {
      main: "#F5F9FF",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 750,
      fontFamily: "Roboto-Medium",
    },
    h3: {
      fontWeight: 350,
      fontFamily: "Roboto-Medium",
    },
    h6: {
      fontWeight: 500,
      fontFamily: "Roboto-Medium",
    },
    subtitle1: {
      margin: "20px 0px",
      fontFamily: "Roboto-Medium",
    },
  },
});
import CommunityForumController, {
  Props,
  configJSON,
  ICommunityObject
} from "./CommunityForumController";
// Customizable Area End

export default class CommunityForum extends CommunityForumController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start

    super.componentDidMount();
    await StorageProvider.get('userDetail').then((result:any) => {
      this.setState({userDetail:JSON.parse(result)})
    });
    this.alldata();
    this.getAllGroups();

    // Customizable Area End
  }

  alldata = async () => {
    this.getAllUpadatedPost_Api();
  };

  attachmentsView=(item:any)=>{
    let imgLen = item?.images?.length;
    let vidLen = item?.videos?.length;
    let audLen = item?.audios?.length;
    let docLen = item?.pdfs?.length;

    if(imgLen > 0 && imgLen == 1 && (vidLen == 0 || audLen == 0 || docLen == 0)){
        return this.showSingleImage(item?.images)
    }else{
      return this.showMultipleAttachments(item?.images, item?.videos, item?.audios, item?.pdfs)
    }
  }

  showSingleImage=(imgs:any)=>{
    let imgUrl = imgs[0]?.url;
    return(
  <Grid container justifyContent="flex-start" data-test-id="imgClick" onClick={this.handleClickImage.bind(this,imgs)} style={{marginTop:10}}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <img
              src={imgUrl} //"https://images.unsplash.com/photo-1608848461950-0fe51dfc41cb?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D"
              style={{ maxWidth: '100%', maxHeight: '280px', objectFit: 'contain', borderRadius: 6 }}
            />
          </Grid>
        </Grid> 
    )    
  }

  showMultipleAttachments=(imgs:any, vdos:any, auds:any, docs:any)=>{
    let shouldShowBothVidDoc = (vdos.length > 0 || docs.length > 0);
    let shouldShowBothImgAud = (imgs.length-1 > 0 || auds.length > 0);
    let showBothVidDoc = (vdos.length > 0 && docs.length > 0);
    let showBothImgAud = (imgs.length-1 > 0 && auds.length > 0);

    return (
      <Grid container spacing={2} style={{marginTop:10}}>        
        {imgs.length > 0 && <Grid item xs={12} sm={6} md={4} lg={4} key={'index3'}>
            <Card onClick={this.handleClickImage.bind(this,imgs)} data-test-id="imgClick1">
              <CardMedia
                component="img"
                height="140"
                image={imgs[0]?.url}
              />
            </Card>
        </Grid>}
       {shouldShowBothVidDoc && <Grid item xs={12} sm={6} md={4} lg={4} key={'index1'} style={showBothVidDoc?webStyle.showBoth as React.CSSProperties:webStyle.showSingle as React.CSSProperties}>
          {vdos.length > 0 && <Card style={{
              backgroundColor: "#A0C5FF",
              width: '290.67px',
              height: '117.56px',
              borderRadius: '9.42px',
              
            }}
            onClick={this.handleClickVideo.bind(this,vdos)} data-test-id="vdoClick">
              <Box style={{
                padding: '8px',
                margin: "10px",
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <PlayCircleFilledIcon style={{ color: 'white', height: "33px", width: "33px" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: '#FFFFFF',
                }}>
                  {vdos.length} more
                </Typography>
              </Box>
          </Card>}
          {docs.length > 0 && <Card style={{
              backgroundColor: "#18EFA1",
              width: '290.67px',
              height: '117.56px',
              borderRadius: '9.42px',
            }}
            onClick={this.handleClickDocument.bind(this,docs)} data-test-id="docClick">
              <Box style={{
                padding: '8px',
                margin: "10px",
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <DescriptionIcon style={{ color: 'white', height: "33px", width: "33px" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: '#FFFFFF',
                }}>
                  {docs.length} more
                </Typography>
              </Box>
          </Card>}
        </Grid>}
        {shouldShowBothImgAud && <Grid item xs={12} sm={6} md={4} lg={4} key={'index2'} style={showBothImgAud?webStyle.showBoth as React.CSSProperties:webStyle.showSingle as React.CSSProperties}>
          {imgs.length-1 > 0 && <Card style={{
              backgroundColor: "#FFD1A8",
              width: '290.67px',
              height: '117.56px',
              borderRadius: '9.42px',
              
            }}
            onClick={this.handleClickImage.bind(this,imgs)} data-test-id="imgClick2"
            >
              <Box style={{
                padding: '8px',
                margin: "10px",
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <PhotoLibraryIcon style={{ color: 'white', height: "33px", width: "33px" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: '#FFFFFF',
                }}>
                  {imgs.length-1} more
                </Typography>
              </Box>
          </Card>}
          {auds.length > 0 && <Card style={{
              backgroundColor: "#B0E57C",
              width: '290.67px',
              height: '117.56px',
              borderRadius: '9.42px'
            }}
            onClick={this.handleClickAudio.bind(this,auds)} data-test-id="audClick">
              <Box style={{
                padding: '8px',
                margin: "10px",
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}>
                <LibraryMusicIcon style={{ color: 'white', height: "33px", width: "33px" }} />
                <Typography style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: '0em',
                  textAlign: 'left',
                  color: '#FFFFFF',
                }}>
                  {auds.length} more
                </Typography>
              </Box>
          </Card>}
        </Grid>}
      </Grid>
    )
  }

  renderPostFlatlistCell = (item: any, index: number) => {
    
    return (
      <Box style={webStyle.container1} key={item.id}>
      <Box style={{display:'flex'}}>
        {item.attributes?.creator_image ? <img style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
            }}
            src={item.attributes.creator_image}
            /> : <AccountCircleIcon style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              color:"#64748B"}}/>}
      
      <Box style={{display:'flex', flexDirection:'column', alignItems:"flex-start", marginLeft:10, width:'100%'}}>
        <Box style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
        <Typography style={webStyle.groupNameForPost as React.CSSProperties}>
        {item.attributes?.creator_name}
            </Typography>
            <Box style={{display:'flex', flexDirection:'row', alignItems:"center"}}>
    
            <Typography  style={webStyle.hrsAgo as React.CSSProperties}>
            {item.attributes?.created_at}
            </Typography>
    
            {this.state.userDetail?.id == item?.attributes?.account_id && <MoreHoriz data-test-id="moreClick" onClick={(event)=>this.handleClick(event, item.id)} style={{marginLeft:10, color:"#64748B"}}/>}
          
            </Box>
        </Box>
        <Typography variant="body2" color="textSecondary" align="center" style={webStyle.postDescription as React.CSSProperties}>
        {item.attributes?.description}
            </Typography>
            {/* <Chip
              key={1}
              label={"#Ecosystem"}
              clickable
              style={{ margin: '4px', backgroundColor:'#E2E8F0' }}
            /> */}

        {this.attachmentsView(item?.attributes)}


      {/* <Grid item xs={4} style={{height:280, width:'auto'}}>
      <img src={"https://images.unsplash.com/photo-1608848461950-0fe51dfc41cb?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D"}
       style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius:6 }} />
      </Grid> */}
{/* <ImageContainer images={[
  "https://images.unsplash.com/photo-1608848461950-0fe51dfc41cb?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D",
  // "http://www.shadowsphotography.co/wp-content/uploads/2017/12/photography-01-800x400.jpg"
]}/> */}
      </Box>
    </Box>
    </Box>
    );
  };

  getDialogContent=()=>{
    switch (this.state.clickedMediaType) {
      case "image":   return <CardMedia
      component="img"
      height="140"
      image={this.state.selectedAttachmentData[this.state.activeStep].url}
    />;
    case "video":   return <CardMedia
    component="video"
    title={this.state.selectedAttachmentData[this.state.activeStep].name}
    height="140"
    controls
  >
    <source src={this.state.selectedAttachmentData[this.state.activeStep].url} type="video/mp4" />
    Your browser does not support the video tag.
  </CardMedia>;
    case "audio" : return <audio controls src={this.state.selectedAttachmentData[this.state.activeStep].url} />
    case "document" : 
    return <Card>
    <CardContent style={webStyle.docView as React.CSSProperties}>
      <DescriptionIcon style={{height: "33px", width: "33px", color: '#18EFA1'}}/>
      <Typography style={webStyle.docText as React.CSSProperties}>{this.state.selectedAttachmentData[this.state.activeStep]?.name}</Typography>
    </CardContent>
  </Card>
    }
  

          
  }

  showDialog=()=>{
    const showPrevious = this.state.activeStep > 0;
    const showNext = this.state.activeStep < this.state.selectedAttachmentData.length - 1;

    return <Dialog open={this.state.openAttachment} onClose={this.handleCloseAttachmentPopup} maxWidth="xl" 
    PaperProps={{
      style: {
        alignItems:"center",
        backgroundColor:"transparent",
      },
    }}
  >
    <Box style={{display:'flex', 
        flexDirection:'row',
        alignItems:"center"}}>
    <Box>
    {showPrevious && (
            <KeyboardArrowLeftIcon style={{ color: 'white' }} onClick={this.onPrevious} data-test-id="prevBtn"/>
          )}
  </Box>
    <DialogContent>
    
    {this.getDialogContent()}
    
  </DialogContent>
  <Box>
  {showNext && <KeyboardArrowRightIcon style={{ color: 'white' }} onClick={this.onNext} data-test-id="nextBtn"/>}
  </Box>
  </Box>
  <Box style={{display:'flex', alignItems:"center"}}>
  <GetAppIcon style={{ color: 'white', height:"14.67px", width:"14.67px",cursor:'pointer' }} onClick={this.handleDownload}
  data-test-id="dwnldIcnBtn"/>
  <Typography data-test-id="dwnldTxtBtn" onClick={this.handleDownload} style={{
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '22px',
      letterSpacing: '0em',
      textAlign: 'center',
      color: 'white',
      marginLeft:"10px",
      cursor:'pointer'
  }}>
    Download
  </Typography>
  </Box>
</Dialog>
  }


  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Box style={webStyle.rootContainer}>
      {this.showDialog()}
      <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.onDeletePost_Api} data-test-id="deleteBtn">
            <Typography  style={webStyle.postDescription as React.CSSProperties}>
            Remove
            </Typography>
            </MenuItem>
          </Menu>
     <CreateNewPost open={this.state.open} handleClosePopup={this.handleClosePopup} getAllUpadatedPost_Api={this.getAllUpadatedPost_Api} navigation={undefined} id={""}/>
        <Grid container>
        <Grid item xs={10}>
      <Hidden mdDown>
      <Box style={webStyle.container}>
    <div style={webStyle.notificationView}>
      <Typography style={webStyle.mainText as React.CSSProperties}>
      Community
      </Typography>
      <Box style={webStyle.backArrow} onClick={this.handleNotification} data-test-id="notiBtn">
      <NotificationsNoneIcon style={webStyle.iconStyle} />
      <Typography style={webStyle.markAllReadText as React.CSSProperties} >
      Notifications
      </Typography>
      </Box>
    </div>
      </Box>
      <Box style={webStyle.container}>
    <div style={webStyle.notificationView}>
      <Typography style={webStyle.newsFeedText as React.CSSProperties}>
      Newsfeed
      </Typography>
      <div style={webStyle.backArrow}>
      <Paper component="form" style={webStyle.root} 
      aria-describedby={this.state.anchorElSearch?"search-popper":undefined} 
          onClick={this.handleSearchClick} data-test-id="srchBtn"
          >

        <IconButton style={webStyle.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          style={webStyle.input as React.CSSProperties}
          data-test-id="searchinput"
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          value={this.state.searchTerm}
          onChange={this.handleSearchChange}
          endAdornment={
            <InputAdornment position="end">
              {this.state.searchTerm && (
                <IconButton
                  edge="end"
                  aria-label="clear search"
                  onClick={this.clearSearch}
                  data-test-id="clrBtn"
                >
                  <HighlightOffIcon style={{color:"#E2E8F0"}} />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <Popper id={this.state.anchorElSearch?"search-popper":undefined} open={Boolean(this.state.anchorElSearch)} anchorEl={this.state.anchorElSearch}>
        {this.state.searchTerm!="" && this.state.filteredResults?.length==0 && <div style={webStyle.main as React.CSSProperties}>
          <img src={searchImage} style={{height:70, width:70, margin:"20px 0px"}}/>
        <Typography style={webStyle.searchNotFoundText as React.CSSProperties}>
          Search not found
        </Typography>
        <Typography color="textSecondary" align="center" style={webStyle.searchDescription as React.CSSProperties}>
          The Search you were looking for not found.
        </Typography>
        <Typography color="textSecondary" align="center" style={webStyle.postDescription as React.CSSProperties}>
          Please try again with different keyword
        </Typography>
      </div>}
        </Popper>
      </Paper>
      </div>
    </div>
      </Box>

      </Hidden>
      <Box style={webStyle.container} onClick={this.handleOpenPopup} data-test-id="opnBtn">
      <div style={webStyle.notificationInputView}>
      {/* <Paper component="form" style={webStyle.root1}> */}
      <div style={webStyle.notificationInputChildView}>
        <div style={webStyle.root1}>
        <IconButton style={webStyle.iconButton} aria-label="menu">
          <AccountCircleIcon />
        </IconButton>
        <Typography style={webStyle.input2 as React.CSSProperties}>
        Hey, share what's on your mind...
        </Typography>
        </div>
        <IconButton type="submit" style={webStyle.iconButton} aria-label="search">
          <AttachmentIcon />
        </IconButton>
        
        </div>
      {/* </Paper> */}
      </div>
      </Box>

      <Box style={webStyle.containerWithoutBorder}>
      <div style={webStyle.notificationView}>
      <Typography style={webStyle.groupsToFollow as React.CSSProperties}>
      Groups to follow
      </Typography>
      <div style={webStyle.backArrow as React.CSSProperties} onClick={this.goToCreateGroup} data-test-id="crtbtn">
      <Typography style={webStyle.createNewGroup as React.CSSProperties} >
      + Create new group
      </Typography>
      </div>
    </div>

    <Grid style={{marginTop:10, display:'flex',}} container>
      {this.state.groups?.map((item:any)=>{        
        let e = item?.data;
            return <GroupCard
            key={e.attributes.name}
            onGroupClick={this.onGroupClick.bind(this,e.id)}
        groupData={{
          groupName: e.attributes.name,
          // groupCategory: e.attributes.description,
          groupImage: e.attributes.icon_image,
        }}
        />
  })}
      {this.state.groups.length >= 9 && 
      <Grid item xs={1} 
      style={{ 
        backgroundColor:"#fff",
      margin:"0px 10px 8px 0px",
      maxWidth: 122,
      flexShrink: 0,
      minWidth: 122,
      maxHeight: 131,
      minHeight: 131,
      borderRadius: 8,
      border: '1px solid #E2E8F0', // You can adjust the border style
      gap: 6,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:"center"
      // padding: '12px 8px 0 8px',
        }}><Typography style={webStyle.createNewGroup as React.CSSProperties}>
          View all Groups</Typography></Grid>}
</Grid>
    </Box>

        {/* <Grid container> */}
         
           {this.state.searchTerm == "" ? this.state.postFlatlistItemSelect?.map((item, index) =>
            this.renderPostFlatlistCell( item , index)) : this.state.filteredResults?.map((item, index) =>
            this.renderPostFlatlistCell( item , index))}
         
          {/* <Button
            data-test-id="ButtonClick"
            {...this.gotoCreatePost}
            style={webStyle.fixbutton as React.CSSProperties}
            startIcon={<AddCircleIcon />}
          />

        </Grid> */}
</Grid>
<Grid item xs={2} style={webStyle.containerWithoutBorder1}>
<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography style={{
             fontFamily: 'Inter',
             fontSize: '14px',
             fontWeight: 600,
             lineHeight: '22px',
             letterSpacing: '0em',
             textAlign: 'left',
             color: '#475569',
          }}>Trending Nowadays</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
</Grid>
        </Grid>
      </Box>
    </ThemeProvider>

      // Customizable Area End
    );
  }

}

// Customizable Area Start
interface GroupCardProps {
  groupData: {
    groupName: string;
    // Add other properties as needed with their types
    groupImage: string;
  };
  onGroupClick: any;
}
const GroupCard : React.FC<GroupCardProps> = ({ groupData,onGroupClick }) => {
  const { groupName, groupImage } = groupData;

  return (
    <Grid item xs={1} 
    onClick={()=>onGroupClick()}
    style={{ 
      backgroundColor:"#fff",
      margin:"0px 10px 8px 0px",
      maxWidth: 122,
      flexShrink: 0,
      minWidth: 122,
      maxHeight: 131,
      minHeight: 131,
      borderRadius: 8,
      border: '1px solid #E2E8F0', // You can adjust the border style
      gap: 6,
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: 'space-between',
      alignItems:"center"
      // padding: '12px 8px 0 8px',
      }}>
        <div style={{padding:0,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        }}>
      {groupImage ? <CardMedia
        component="img"
        style={{
          width: '56px',
          height: '56px',
          borderRadius: '50%',
          margin:"10px 0px"
        }}
        image={groupImage}
        title={groupName}
      /> : <AccountCircleIcon style={{
        width: '56px',
        height: '56px',
        borderRadius: '50%',
        margin:"10px 0px", color:"#64748B"}}/>}
      {/* <CardContent style={{
        padding:0,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}> */}
        <Typography variant="h6" component="h2" align="center" style={webStyle.groupName as React.CSSProperties}>
          {groupName}
        </Typography>
        </div>
        <div style={{ justifyContent: 'center',alignItems: 'center', backgroundColor: '#F5F9FF', width:"100%", display:"flex", height:'34px', maxHeight:'34px'}}>
        <Typography variant="body2" color="textSecondary" align="center"
            style={webStyle.joinGroup as React.CSSProperties}
          >+ Join Group
          </Typography>
        </div>
      {/* </CardContent> */}
    </Grid>
  );
};

const webStyle = {
  showBoth:{
    display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', maxHeight:290, height:"290px", alignItems:'center' 
  },
  showSingle:{
    display: 'flex', flexDirection: 'column', maxHeight:290, paddingTop:"20px", alignItems:'center' 
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor:'#fff',
    width: 400,
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    borderBottom: '1px solid #E2E8F0', // Bottom border
    borderRight: '1px solid #E2E8F0',   // Right border
    borderLeft: '1px solid #E2E8F0',    // Left border
  
 },
  groupName:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#475569',
    whiteSpace: 'nowrap', // Prevent text from wrapping
    overflow: 'hidden', // Hide overflowed text
    textOverflow: 'ellipsis', // Display ellipsis when text overflows
    width:"90%"
  },
  groupNameForPost:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#475569',
  },
  hrsAgo:{
    fontFamily: 'Nunito',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0em',
  textAlign: 'center',
  color: '#64748B',
  },
  groupCategory:{
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#475569',
  },
  postDescription:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#64748B',
  },
  searchDescription:{
    marginTop:"10px",
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#64748B',
  },
  joinGroup:{
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#475569',
      cursor:'pointer'
  },
  groupsToFollow:{  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#475569',
},
createNewGroup:{
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#475569',
  cursor:'pointer'
},
  input: {
    fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#64748B',
  width:"85%"
  },
  input1:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#64748B',
    width:"100%",
    alignSelf:"center"
  },
  input2:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#64748B',
    width:"100%",
    alignSelf:"center",
    cursor: 'pointer'
  },
  description:{
    width: '100%', // Adjust the width as needed
    border: 'none',
    resize: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    outline: 'none', // Remove the default focus outline
    color: '#64748B',
    paddingLeft:"77px",
    marginTop:"10px"
  },
  attachment:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: "#64748B",
    textTransform: 'capitalize' 
  },
  post:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color:'#fff',
    textTransform: 'capitalize' ,
    padding:"0px 10px"
  },
  iconButton: {
    padding: 10,
    color:"#64748B"
  },
  root:{
    // padding: '2px 4px',
    // display: 'flex',
    // alignItems: 'center',
    width: 400,
  // padding: '5px 8px',
  borderRadius: '6px',
  // border: '1px solid',
  },
  root1:{
    display: 'flex',
    width:"100%"
  },
  root2:{
    display: 'flex',
    width:"100%",
    borderBottom: '1px solid #94A3B84D',
  },
  backArrow: {
    marginBottom: "1px",
    alignItems: 'center',
    display: 'flex',
  },
  iconStyle: {color:"#0B357D", cursor: 'pointer',},
  markAllReadText:{
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#0B357D',
    cursor: 'pointer',
  },
  newsFeedText:{
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#475569',
  },
  docView:{
      height:"140px",
      width:"140px",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
  },
  docText:{
    fontFamily: 'Inter',
    fontSize: '9px',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#475569',
  },
  searchNotFoundText:{
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#475569',
    marginTop:"10px"
  },
  communityContainer: {
    borderRadius:8,
    padding: "10px",
    border: '1px solid #E2E8F0',
    backgroundColor:'#fff',
    marginBottom:"10px",
    marginTop:"20px",
  },
  rootContainer:{
    padding: "10px 0px 10px 20px",
  },
  createAPost:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#0B357D',
    margin:20,
  },
  container: {
    borderRadius:8,
    padding: "10px",
    border: '1px solid #E2E8F0',
    backgroundColor:'#fff',
    marginBottom:"10px"
  },
  container1: {
    borderRadius:8,
    padding: "10px",
    border: '1px solid #E2E8F0',
    backgroundColor:'#fff',
    marginBottom:"10px",
    marginTop:'10px'
  },
  containerWithoutBorder:{
    padding: "0px 0px 10px 0px",
    overflow:'none'
  },
  containerWithoutBorder1:{
    padding: "0px 0px 0px 20px",
  },
  notificationView:{
    alignItems: 'center',
    display: 'flex',
    justifyContent: "space-between"
  },
  notificationInputChildView:{
    width:"100%",
    display: 'flex',
    justifyContent: "space-between"
  },
  notificationInputView:{
    // alignItems: 'center',
    display: 'flex',
    // justifyContent: "space-between"
  },
  mainText: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    color: '#0B357D',
  },
  card: {
    margin: 20,
    width: "100%",
    position: "relative",
    left: 0,
  },

  giveaway: {
    width: "100%",
    height: "auto",
    padding: 0,
    margin: 0,
  },
  fixbutton: {
    bottom: 50,
    right: 50,
    position: 'fixed',
    width: "100px",
    height: '100px',
    zIndex: 9999,
    backgroundColor: '#f6f6f6'
  },


  tag: {
    padding: 10,
  },
};
// Customizable Area End
