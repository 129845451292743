Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";

exports.btnExampleTitle = "CLICK ME";
exports.helpcentreAPIEndPoint = "/help_centre/question_type";
exports.httpGetType = "GET";

exports.GET = 'GET'
exports.POST = "POST";
exports.DELETE = "DELETE";
exports.createPostEndPoint = '/bx_block_posts/posts'
exports.getAllTagesApiEndPoint = '/bx_block_hashtags/hashtags'
exports.getAllGroupsApiEndPoint = '/bx_block_account_groups/groups'

// Customizable Area End
