import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "framework/src/StorageProvider.web";
import toast from "react-hot-toast";

export const configJSON = require("./config");

export interface ICommunityObject {
  id: string,
  type: string,
  attributes: {
      images:any;
      id: number,
      name: string,
      description: string,
      body: string,
      account_id: number,
      created_at: string,
      updated_at: string,
      model_name: string,
      creator_name: string,
      creator_image: string,
      account: {
          id: string,
          type: string,
          attributes: {
              activated: boolean,
              email: string,
              type: string,
              created_at: string,
              updated_at: string,
              device_id: null,
              unique_auth_id: string,
              full_name: string,
              user_name: string,
              dob: null,
              country: null,
              platform: null,
              style: string,
              image: string
          }
      }
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  searchTerm: string,
  userDetail:any;
  anchorEl:any;
  idToDelete:any;
  open:boolean;
  openAttachment:boolean;
  toggle: boolean;
  isLoading: boolean;
  token: string;
  postFlatlistItemSelect: ICommunityObject[];
  filteredResults: ICommunityObject[];
  activeStep:any;
  clickedMediaType:string;
  selectedAttachmentData:any;
  audios:any;
  videos:any;
  images:any;
  pdfs:any;
  groups:any;
  refreshing: boolean;
  openSearch: boolean;
  anchorElSearch:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCreatedPostApiCallId: string="";
  getAllGroupsApiCallId: string="";  
  deletePostApiCallId: string="";  
  reactSwipeEl: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.reactSwipeEl=null;
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userDetail:{id:""},
      searchTerm: '',
      anchorEl:null,
      idToDelete:"",
      open:false,
      openAttachment:false,
      toggle: false,
      isLoading: false,
      groups:[],
      token: "",
      refreshing: false,
      openSearch: false,
      anchorElSearch: null,
      postFlatlistItemSelect: [],
      activeStep:0,
      selectedAttachmentData:[],
      clickedMediaType:"",
      audios:[
    ],
      videos:[],
      images:[],
      pdfs:[],
      filteredResults:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

 
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getAllCreatedPostApiCallId) {
          if (!responseJson.errors) {
            
            this.setState({ isLoading: false, refreshing: false,
              postFlatlistItemSelect: responseJson?.data })
           
          }
         
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.getAllGroupsApiCallId) {
          if (!responseJson.errors) {
            let data = [];
            if(responseJson?.length >= 9){
              data = responseJson?.slice(0, 9)
            }else{
              data = responseJson
            }
            this.setState({ isLoading: false, refreshing: false,
              groups: data
            })
           
          }
         
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if(apiRequestCallId === this. deletePostApiCallId){
          if (!responseJson.errors) {
            toast.success(responseJson?.message);
            this.handleClose()
            this.getAllUpadatedPost_Api()
          } else {
            this.setState({ isLoading: false, refreshing: false })
            toast.error(responseJson?.message);
            
          }
         
        }
      }
    }
    
    // Customizable Area End
  }

  // Customizable Area Start

  gotocreatePost = {
    onPress: () =>
        this.props.navigation.replace('CreateNewPost')
  }

  gotoCreatePost = {
    onClick: () =>
        this.props.navigation.navigate('CreateNewPost')
  }

  getAllUpadatedPost_Api = async () => {

    let token = "";

    await StorageProvider.get('loginToken').then((result) => {
      token = result;
    });
    
    this.setState({ isLoading: true, refreshing:true })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    this.setState({ isLoading: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    this.getAllCreatedPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPostEndPoint    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  getAllGroups = async () => {

    let token = "";

    await StorageProvider.get('loginToken').then((result) => {
      token = result;
    });
    
    this.setState({ isLoading: true, refreshing:true })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    this.setState({ isLoading: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    this.getAllGroupsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllGroupsApiEndPoint);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  onDeletePost_Api = async () => {
    if(!this.state.idToDelete){
      return
    }

    let token = "";

    await StorageProvider.get('loginToken').then((result) => {
      token = result;
    });

    const header = {
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPostEndPoint+'/'+this.state.idToDelete
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };


  handleClick = (event:any, id:any) => {
    this.setState({anchorEl:event.currentTarget, idToDelete:id})
  };

  handleClose = () => {
    this.setState({anchorEl:null})
  };

  handleOpenPopup = () => {
    this.setState({open:true})
  }

  handleClosePopup = () => {
    this.setState({open:false})
  }

  handleCloseAttachmentPopup = () => {
    this.setState({openAttachment:false})
  }

  timeSince(date: string) {
    let seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  handleNotification=()=>{
    this.props.history.push('/notifications')
  }

  handleSearchChange = (event:any) => {
    let query = event.target.value;
    this.setState({ searchTerm: query });
    // Add your search logic here, e.g., filter data based on the search term
    // Filter data based on the search query
    const filteredResults = this.state.postFlatlistItemSelect.filter(item =>
      item?.attributes?.description?.toLowerCase()?.includes(query.toLowerCase())
    );   

    this.setState({filteredResults: filteredResults})
  };

  clearSearch=()=>{
    this.setState({ searchTerm: "", filteredResults:[] });
  }

  onGroupClick=(id:any)=>{
    this.props.history.push(`/communitygroup/${id}`)
  }

  goToCreateGroup=()=>{
    this.props.history.push('/creategroup')
  }

  onPrevious=()=>{
    this.setState((prevState:any) => ({
      activeStep: Math.max(prevState.activeStep - 1, 0),
    }));
  }

  onNext=()=>{
    const { selectedAttachmentData } = this.state;
    this.setState((prevState:any) => ({
      activeStep: Math.min(prevState.activeStep + 1, selectedAttachmentData.length - 1),
    }));
  }

  handleDownload = async () => {
    const fileUrl = this.state.selectedAttachmentData[this.state.activeStep].url; // Replace with your file URL

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a download link
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.state.selectedAttachmentData[this.state.activeStep].name; // Specify the desired file name

      // Append the link to the document and trigger a click
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.log('Error downloading file:', error);
    }
  };

  handleSearchClick=(event:any)=>{    
    this.setState({anchorElSearch: this.state.anchorElSearch ? null : event.currentTarget})
  }

  handleClickImage=(data:any)=>{
    this.setState({openAttachment:true, clickedMediaType:"image", activeStep:0, selectedAttachmentData:data})
  }
  handleClickVideo=(data:any)=>{
    this.setState({openAttachment:true, clickedMediaType:"video", activeStep:0, selectedAttachmentData:data})  
  }
  handleClickAudio=(data:any)=>{
    this.setState({openAttachment:true, clickedMediaType:"audio", activeStep:0, selectedAttachmentData:data})
  }
  handleClickDocument=(data:any)=>{
    this.setState({openAttachment:true, clickedMediaType:"document", activeStep:0, selectedAttachmentData:data})
  }
  // Customizable Area End
 
  
}