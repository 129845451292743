
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import StorageProvider from "framework/src/StorageProvider.web";
import toast from "react-hot-toast";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open:any;
  handleClosePopup:any;
  getAllUpadatedPost_Api:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  title: string;
  description: string;
  descError:boolean;
  loading: boolean;
  token: string;
  isAllFilesUploaded:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateNewPostController extends BlockComponent<
  Props,
  S,
  SS
> {
  createnewPostApiCallId: string="";
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      title: '',
      description: '',
      descError:false,
      loading: false,
      token: "",
      isAllFilesUploaded:true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ loading: false })
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createnewPostApiCallId) {
          this.setState({isAllFilesUploaded:true,description:"", descError:false,})
          if (!responseJson.error) {            
            this.props.getAllUpadatedPost_Api()
            this.props.handleClosePopup()
          } else {
            this.props.handleClosePopup()
            this.parseApiErrorResponse(responseJson);
          }
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getToken()
  }

  customBack = {
    onPress: () =>
      this.goBack()
  }

  onNextButtonclick = {
    onPress: () =>
      this.onCreatePost_Api([])
  }

  checkFileType=(element:any, formData:any)=>{
    let fileType = element.type;
    if (fileType.startsWith('image/')) {
      formData.append("images[]", element)
    } else if (fileType.startsWith('video/')) {
      formData.append("videos[]", element)
    } else if (fileType.startsWith('audio/')) {
      formData.append("audios[]", element)
    } else if (
      fileType === 'application/pdf' ||
      fileType === 'application/msword' || // .doc
      fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || // .docx
      fileType === 'application/vnd.ms-powerpoint' || // .ppt
      fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || // .pptx
      fileType === 'application/vnd.ms-excel' || // .xls
      fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
    ) {
      formData.append("pdfs[]", element)
    }

    return formData
  }

  onCreatePost_Api = async (selectedFiles:any) => {
    if(this.state.description.trim() == ""){
      this.setState({descError:true});
      return
    }

    this.setState({ loading: true, isAllFilesUploaded:false })

    const header = {
      token: this.state.token
    };
    let formData = new FormData();

    formData.append("description", this.state.description)
    

    for (let index = 0; index < selectedFiles.length; index++) {
      const element = selectedFiles[index];
      formData = this.checkFileType(element,formData) 
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createnewPostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPostEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  setTitle = (value: string) => {
    this.setState({ title: value })
  }

  setDescription = (value: string) => {
    this.setState({ description: value })
  }


  goBack = () => {

    this.props.navigation.goBack()
  }

  handleDescriptionChange = (event: any) =>{
    this.setState({ description: event.target.value, descError:false, });
  };

  getToken=()=>{
    StorageProvider.get('loginToken').then((result: any) => {
      this.setState({token:result})      
    });
  }

  closePopup=()=>{
    this.setState({description:"", isAllFilesUploaded:true, descError:false,})
    this.props.handleClosePopup();
  }
  // Customizable Area End
}
