import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  TextField,
  Dialog,
  Avatar,
  TextareaAutosize,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloseIcon from '@material-ui/icons/Close';
import { attachment } from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h1: {
      fontWeight: 750,
    },
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import CreateNewPostController, {
  Props
} from "./CreateNewPostController";
import toast from "react-hot-toast";
// Customizable Area End

export default class CreateNewPost extends CreateNewPostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Dialog
          open={this.props.open}
          onClose={this.closePopup}
          aria-labelledby="form-dialog-title"
          maxWidth="sm" 
          fullWidth
        >
          <Box style={webStyle.bg}>
          <Typography style={webStyle.createAPost as React.CSSProperties}>Create a Post</Typography>
          </Box>
          {!this.state.isAllFilesUploaded ? ( // Display loader if isUploaded is false
          <Box style={{height:"180px", width:"588px", justifyContent:"center", display:'flex', alignItems:'center', flexDirection:'column'}}>
            <CircularProgress style={{color:"#64748B"}}/>
            <Typography 
                style={webStyle.input2 as React.CSSProperties}>
                  Uploading...
                </Typography>
          </Box>
      ) : (
          <Box >
            <div style={webStyle.root2}>
              <AccountCircleIcon style={webStyle.avatar}/>
              <Typography 
                style={webStyle.input1 as React.CSSProperties}>
                  Hey, share what's on your mind...
                </Typography>
              </div>
              <TextareaAutosize
                style={webStyle.description as React.CSSProperties}
                value={this.state.description}
                data-test-id="descritpion"
                placeholder="Write description here..."
                minRows={3} // Minimum number of rows to display
                maxRows={10} // Maximum number of rows before scrolling
                onChange={this.handleDescriptionChange}
              />
               {this.state.descError && <Typography 
                style={webStyle.descriptionError as React.CSSProperties}>
                  Description can not be empty
                </Typography>}
              
              <FileUpload onCreatePost_Api={this.onCreatePost_Api}/>
          </Box>)}
        </Dialog>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start

export class FileUpload extends React.Component<{onCreatePost_Api:any},{selectedFiles:any, sizeError:boolean}> {
  constructor(props:any) {
    super(props);
    this.state = {
      selectedFiles: [],
      sizeError:false,
    };
  }

  checkFileSize=(sfiles:any)=> {
    let files = [...this.state.selectedFiles, ...sfiles]

    if (!files) {
      return false;
    }
  
    // Calculate the total size of all files
    const totalSize:any = Array.from(files).reduce((acc, file:any) => acc + (file.size || 0), 0);
  
    // Convert total size to megabytes
    const totalSizeMB = totalSize / (1024 * 1024);
  
    // Check if the total size exceeds 40MB
    if (totalSizeMB > 40) {
      return false;
    }
  
     // Check if any individual file size exceeds 40MB
    for (const file of files) {
      const fileSizeMB = (file.size || 0) / (1024 * 1024);
      if (fileSizeMB > 40) {
        return false;
      }
    }
  
    // If no file size exceeds 40MB, return true
    return true;
  }

  handleFileChange = async (event:any) => {
    console.log('event.target.files ',event.target.files);
    let files = event.target.files;
    
    let isExeedingUploadSize = await this.checkFileSize(Array.from(event.target.files));
    
    if(!isExeedingUploadSize){
      this.setState({sizeError: true})
      return;
    }

    this.setState({sizeError: false})

    let selected = [...this.state.selectedFiles]
    const selectedFiles = Array.from(files);
    this.setState({ selectedFiles: [...selectedFiles, ...selected] });
    console.log("selectedFiles", selectedFiles)
  };

  handleRemoveFile = (index: any) => {
    const { selectedFiles } = this.state;
    const newSelectedFiles = selectedFiles.filter((_:any, i:any) => i !== index);
    this.setState({ selectedFiles: newSelectedFiles });
  };  

  render() {
    const { selectedFiles } = this.state;

    return (
      <div>
        <Box style={{padding: 20}}>
         {selectedFiles.length > 0 && (
              selectedFiles.map((file:any, index:any) => (
              <div key={file.name+index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={webStyle.input1 as React.CSSProperties}>
                  {file.name}
                </Typography>
                  <CloseIcon
                    onClick={() => this.handleRemoveFile(index)}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                  />
              </div>
              ))
        )}
        {this.state.sizeError && <Typography 
                style={webStyle.sizeError as React.CSSProperties}>
                  File size should not exceed 40MB
                </Typography>}
        </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" style={webStyle.box1}>
        <input
          type="file"
          id="fileInput"
          multiple
          style={{ display: 'none' }}
          onChange={this.handleFileChange}
          accept="image/*,video/*,audio/*,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx"
          data-test-id="filechange"
        />
        <label htmlFor="fileInput">
          <Button variant="contained" style={webStyle.b1} component="span">
            <Avatar src={attachment} style={webStyle.logo} />
            <Typography style={webStyle.attachment as React.CSSProperties}>
              Attachment
            </Typography>
          </Button>
        </label>
      <Button
                variant="contained"
                style={webStyle.b2}
                onClick={()=>{
                  this.props.onCreatePost_Api(this.state.selectedFiles)
                }}
                data-test-id="post"
                >
                <Typography style={webStyle.post as React.CSSProperties}>
                  Post
                </Typography>
              </Button>
      </Box>
                </div>
    );
  }
}


const webStyle = {
  b1:{ backgroundColor: '#FFF', color: '#64748B' },
  b2:{ backgroundColor: '#0B357D', color: '#fff' },
  logo:{height:"14.96px", width:"16.79px", marginRight:5},
  box1:{padding: '10px 20px', backgroundColor: '#F1F5F9'},
  bg:{backgroundColor:"#F1F5F9",  },
  avatar:{height:48, width:48, margin:"10px 10px 10px 20px", color:"#64748B"},
  createAPost:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#0B357D',
    margin:20,
  },
  root2:{
    display: 'flex',
    width:"100%",
    borderBottom: '1px solid #94A3B84D',
  },
  attachment:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: "#64748B",
    textTransform: 'capitalize' 
  },
  post:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color:'#fff',
    textTransform: 'capitalize' ,
    padding:"0px 10px"
  },
  input1:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#64748B',
    width:"100%",
    alignSelf:"center",
    cursor: 'pointer'
  },
  input2:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#64748B',
    width:"100%",
    alignSelf:"center",
    cursor: 'pointer'
  },
  hastags: {
    width: "400",
  },
  btntags: {
    borderRadius: 20,
    margin: 10,
    textTransform: "capitalize" as "capitalize",
  },
  content: {
    padding: 40,
  },
  searchTag: {
    paddingTop: 50,
    position: "absolute" as "absolute",
    right: "15%",
  },
  title: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  tag: {
    padding: 10,
  },

  tags: {
    textTransform: "capitalize" as "capitalize",
  },
  description: {
    width: '100%', // Adjust the width as needed
    border: 'none',
    resize: 'none',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    outline: 'none', // Remove the default focus outline
    color: '#64748B',
    paddingLeft:"77px",
    marginTop:"10px",
    maxHeight:"100px"
  },
  descriptionError:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    outline: 'none', // Remove the default focus outline
    color: 'red',
    paddingLeft:"77px",
  },
  sizeError:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    outline: 'none', // Remove the default focus outline
    color: 'red',
  },
  typo: {
    paddingTop: 5,
    paddingLeft: 10,
    textTransform: "capitalize" as "capitalize",
  },
  backIconSet: {
    flexDirection: "row" as "row",
  },
  backIconSize: {
    height: 15,
    width: 15,
  },

  typo3: {
    textTransform: "capitalize" as "capitalize",
  },
};
// export { CreateNewPost };
// export default withRouter(CreateNewPost);
// Customizable Area End
