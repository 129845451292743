// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import ApiCall from '../../../components/src/apiCall';
import toast from "react-hot-toast";
import moment from 'moment';
import React from "react";
import StorageProvider from "framework/src/StorageProvider.web";

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
export interface Props {
    navigation: any;
    id: string;
    history: any;
    match: any;
    location: any
}
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
    data: object
}

export interface ErrorPayloadType {
    messages: string,
    token: string
}

export interface InvalidResponseType {
    errors: Array<ErrorPayloadType>
}
// Customizable Area End

interface S {
    // Customizable Area Start
    openLogs: boolean;
    page: any;
    pageForLogs: any;
    feedbackModalOpen: boolean;
    wfhModalOpen: boolean;
    applyLeaveModalOpen: boolean;
    leaveLogModal: boolean;
    dashboardApiData: any;
    leaveRequestsData: any;
    leaveLogsData: any;
    startDate: string;
    endDate: string;
    reason: string;
    subject: string;
    fristName: string;
    nameError: boolean;
    subjectError: boolean;
    descripationError: boolean;
    oepnCalendar:boolean;
    ranageStartDate:any;
    rangeEndDate:any;
    timesheetData : {
        total_duration: {
            hours:number,
            mins:number
        },
        total_wfh_duration: {
            hours:number,
            mins:number
        },
        total_office_duration: {
            hours:number,
            mins:number
        }
    };
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}


interface CustomInputElement extends HTMLInputElement {
    showPicker: () => void;
}

export default class Dashboardcontrollerweb extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    dashboardGetCallApi: string = "";
    leaveRequestsApiId: string = "";
    leaveLogsApiId: string = "";
    timesheetApiId: string = "";
    applyWfhCallApi: string = "";
    applyLeaveCallApi: string = "";
    clientFeedbackCallApi: string = "";
    wfhStartcalender: React.RefObject<CustomInputElement>;
    wfhEndcalender: React.RefObject<CustomInputElement>;
    leaveStartcalender: React.RefObject<CustomInputElement>;
    leaveEndcalender: React.RefObject<CustomInputElement>;
    timeSheetCalendar: React.RefObject<any>;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];
        // Customizable Area End

        // Customizable Area Start
        this.state = {
            openLogs: false,
            page: 1,
            pageForLogs: 1,
            feedbackModalOpen: false,
            wfhModalOpen: false,
            applyLeaveModalOpen: false,
            leaveLogModal: false,
            dashboardApiData: {},
            leaveRequestsData: {
                paginated_data: [],
                total_count: 0
            },
            leaveLogsData: {
                paginated_data: [],
                total_count: 0
            },
            startDate: "",
            endDate: "",
            reason: "",
            subject: "",
            fristName: "",
            nameError: false,
            subjectError: false,
            descripationError: false,
            oepnCalendar:false,
            ranageStartDate:null,
            rangeEndDate:null,
            timesheetData : {
                total_duration: {
                    hours:0,
                    mins:0
                },
                total_wfh_duration: {
                    hours:0,
                    mins:0
                },
                total_office_duration: {
                    hours:0,
                    mins:0
                }
            }
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.wfhStartcalender = React.createRef<CustomInputElement>();
        this.wfhEndcalender = React.createRef<CustomInputElement>();
        this.leaveStartcalender = React.createRef<CustomInputElement>();
        this.leaveEndcalender = React.createRef<CustomInputElement>();
        this.timeSheetCalendar = React.createRef<any>();
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.handleGetDashboard();
        this.handleGetLeaveRequests();
        this.handleGetTimeSheet();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (this.isValidResponse(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            }
            else if (this.isInValidResponse(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isValidResponse = (responseJson: ValidResponseType) => {
        return responseJson && responseJson.data;
    };
    // Customizable Area End

    // Customizable Area Start
    isInValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };
    // Customizable Area End

    // Customizable Area Start
    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.dashboardGetCallApi) {
            this.onDashboardSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.leaveRequestsApiId) {
            this.onLeaveRequestsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.leaveLogsApiId) {
            this.onLeaveLogsSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.applyWfhCallApi) {
            this.onApplyWfhSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.applyLeaveCallApi) {
            this.onApplyLeaveSuccessCallBack(responseJson)
        }
        if (apiRequestCallId === this.clientFeedbackCallApi) {
            this.onClientFeedbackSuccessCallBack(responseJson)
        }
        if(apiRequestCallId === this.timesheetApiId){
            this.onTimeSheetSuccessCallBack(responseJson)
        }
    };
    // Customizable Area End

    // Customizable Area Start
    apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.dashboardGetCallApi) {
            this.onDashboardFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.leaveRequestsApiId) {
            this.onLeaveRequestsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.leaveRequestsApiId) {
            this.onLeaveLogsFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.applyWfhCallApi) {
            this.onApplyWfhFailureCallBack(responseJson)
        }
        if (apiRequestCallId === this.applyLeaveCallApi) {
            this.onApplyLeaveFailureCallBack(responseJson)
        }
        if (apiRequestCallId === this.clientFeedbackCallApi) {
            this.onClientFeedbackFailureCallBack(responseJson)
        }
    };
    // Customizable Area End

    // Customizable Area Start
    onChangeFristName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ fristName: e.target.value, nameError: false })
    }
    // Customizable Area End

    // Customizable Area Start
    onChangeSubject = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ subject: e.target.value, subjectError: false })
    }
    // Customizable Area End

    // Customizable Area Start
    onChangeReasonTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ reason: e.target.value, descripationError: false })
    }
    // Customizable Area End

    // Customizable Area Start
    onChangeStartDate = (e: any) => {
        this.setState({ startDate: e.target.value })
    }
    // Customizable Area End

    // Customizable Area Start
    onChangeEndDate = (e: any) => {
        this.setState({ endDate: e.target.value })
    }
    // Customizable Area End

    // Customizable Area Start
    capitalizeFirstWord = (inputString: any) => {
        if (typeof inputString !== 'string') {
            return inputString; // Return unchanged if input is not a string
        }

        return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    }

    handleGetDashboard = () => {
        this.dashboardGetCallApi = ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.dashboardGet,
        })
    }
    handleGetLeaveRequests = (page?: any) => {
        if (page) {
            this.setState({ page: page })
        }
        this.leaveRequestsApiId = ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.leaveRequestsEndPoint + `?user_type=other&per_page=4&page=${page || 1}`,
        })
    }
    handleGetLeaveLogs = (pageForLogs?: any) => {
        if (pageForLogs) {
            this.setState({ pageForLogs: pageForLogs })
        }
        this.leaveLogsApiId = ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.dashboarApiMethodType,
            endPoint: configJSON.leaveRequestsEndPoint + `?user_type=self&per_page=4&page=${pageForLogs || 1}`,
        })
    }

    handleGetTimeSheet = (startdate?:string,enddate?:string) => {
        const reqData = {
            "start_date": startdate ? moment(startdate).format('YYYY-MM-DD') : moment().startOf('month').format('YYYY-MM-DD'),
            "end_date": enddate ? moment(enddate).format('YYYY-MM-DD') :  moment().format('YYYY-MM-DD')
          }
        this.timesheetApiId = ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.postMethodType,
            endPoint: configJSON.timesheetEndpoint,
            body:reqData
        })
    }

    onDashboardSuccessCallBack = (response: any) => {
        StorageProvider.set('userDetail', JSON.stringify(response?.data))
        this.setState({ dashboardApiData: response?.data });
    }

    onTimeSheetSuccessCallBack = (response: any) => {
        this.setState({ timesheetData: response?.data });
    }

    onDashboardFailureCallBack = (response: any) => {
        if (response?.errors[0]?.messages) {
            toast.error(response?.errors[0]?.messages)
        } else {
            toast.error(response?.errors[0]?.token)
        }

    }
    onLeaveRequestsSuccessCallBack = (response: any) => {
        this.setState({ leaveRequestsData: response?.data?.message });
    }

    onLeaveRequestsFailureCallBack = (response: any) => {
        if (response?.errors[0]?.messages) {
            toast.error(response?.errors[0]?.messages)
        } else {
            toast.error(response?.errors[0]?.token)
        }
    }
    onLeaveLogsSuccessCallBack = (response: any) => {
        this.setState({ leaveLogsData: response?.data?.message });
    }

    onLeaveLogsFailureCallBack = (response: any) => {
        if (response?.errors[0]?.messages) {
            toast.error(response?.errors[0]?.messages)
        } else {
            toast.error(response?.errors[0]?.token)
        }
    }

    handleApplyWfh = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let body = {
            data: {
                attributes: {
                    start_date: this.state.startDate,
                    end_date: this.state.endDate,
                    reason: this.state.reason,
                    action_type: "wfh",
                    status: "pending"
                }
            }
        }

        this.applyWfhCallApi = ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.postMethodType,
            endPoint: configJSON.applyLeave,
            body
        })
    }

    onApplyWfhSuccessCallBack = (response: any) => {
        toast.success(response?.data?.message);
        this.handleGetDashboard();
        this.setState({ wfhModalOpen: false, startDate: "", endDate: "", reason: "" });
    }

    onApplyWfhFailureCallBack = (response: any) => {
        if (response?.errors[0]?.message) {
            toast.error(response?.errors[0]?.message)
        } else {
            toast.error(response?.errors[0]?.token)
        }

        this.setState({ wfhModalOpen: false, startDate: "", endDate: "", reason: "" });
    }

    handleApplyLeave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let body = {
            data: {
                attributes: {
                    start_date: this.state.startDate,
                    end_date: this.state.endDate,
                    reason: this.state.reason,
                    action_type: "leave",
                    status: "pending"
                }
            }
        }

        this.applyLeaveCallApi = ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.postMethodType,
            endPoint: configJSON.applyLeave,
            body
        })
    }

    onApplyLeaveSuccessCallBack = (response: any) => {
        toast.success(response?.data?.message);
        this.handleGetDashboard();
        this.setState({ applyLeaveModalOpen: false, startDate: "", endDate: "", reason: "" });
    }

    onApplyLeaveFailureCallBack = (response: any) => {
        if (response?.errors[0]?.message) {
            toast.error(response?.errors[0]?.message)
        } else {
            toast.error(response?.errors[0]?.token)
        }
        this.setState({ applyLeaveModalOpen: false, startDate: "", endDate: "", reason: "" });
    }

    handleClientFeedback = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let isError: boolean = false;

        if (this.state.fristName == "") {
            this.setState({ nameError: true })
            isError = true;
        }

        if (this.state.subject == "") {
            this.setState({ subjectError: true })
            isError = true;
        }

        if (this.state.reason == "") {
            this.setState({ descripationError: true })
            isError = true;
        }

        if (isError) {
            return;
        }

        let body = {
            data: {
                attributes: {
                    first_name: this.state.fristName,
                    complaint: this.state.reason,
                    subject: this.state.subject
                }
            }
        }

        this.clientFeedbackCallApi = ApiCall({
            contentType: configJSON.dashboarContentType,
            method: configJSON.postMethodType,
            endPoint: configJSON.clientFeedback,
            body
        })
    }

    onClientFeedbackSuccessCallBack = (response: any) => {
        toast.success(response?.data?.message);
        this.setState({ feedbackModalOpen: false, reason: "", subject: "", fristName: "" });
    }

    onClientFeedbackFailureCallBack = (response: any) => {
        if (response?.errors[0]?.message) {
            toast.error(response?.errors[0]?.message)
        } else {
            toast.error(response?.errors[0]?.token)
        }

        this.setState({ feedbackModalOpen: false, reason: "", subject: "", fristName: "" });
    }

    // Customizable Area End

    // Customizable Area Start
    handleFeedbackmodal = () => {
        this.setState({ feedbackModalOpen: true });
    }

    handleNotification=()=>{
        this.props.history.push('/notifications')
    }

    handleFeedbackModalClose = () => {
        this.setState({ feedbackModalOpen: false, subject: "", reason: "", nameError: false, subjectError: false, descripationError: false });
    }

    handleWfhModal = () => {
        if (this.state.dashboardApiData?.wfh_left > 0) {
            this.setState({ wfhModalOpen: true });
        } else {
            toast.error("You have insufficient wfh balance.")
        }
    }

    handleWfhModalClose = () => {
        this.setState({ wfhModalOpen: false, startDate: "", endDate: "", reason: "" })
    }

    handleapplyLeaveModal = () => {
        if (this.state.dashboardApiData?.leaves_left > 0) {
            this.setState({ applyLeaveModalOpen: true })
        } else {
            toast.error("You have insufficient leave balance.")
        }
    }

    handleLeaveModalClose = () => {
        this.setState({ applyLeaveModalOpen: false, startDate: "", endDate: "", reason: "" })
    }

    handleWfhStartDate = () => {
        this.wfhStartcalender.current?.showPicker()
    }

    handleTimeSheetDateCalendar = ()=>{
        this.setState({oepnCalendar:true})
        this.timeSheetCalendar.current?.setOpen(true)
    }

    handleWfhEndDate = () => {
        this.wfhEndcalender.current?.showPicker()
    }

    handleLeaveStartDate = () => {
        this.leaveStartcalender.current?.showPicker()
    }

    handleLeaveEndDate = () => {
        this.leaveEndcalender.current?.showPicker()
    }

    handleLogout = () => {
        localStorage.removeItem("loginToken")
        this.props.history.push("/signin")
    }

    openLeaveWFHLogs = () => {
        this.setState({ openLogs: true }, () => {
            this.handleGetLeaveLogs()
        })
    }
    closeLeaveWFHLogs = () => {
        this.setState({ openLogs: false, pageForLogs: 1 })
    }

    handleCloseCalendar = () => {
        this.setState({oepnCalendar:false})
    }

    handleSetValue = (dateRange:any) =>{
        this.setState({ranageStartDate:dateRange[0],rangeEndDate:dateRange[1]},()=>{
           if(dateRange[0] && dateRange[1]){
               this.handleGetTimeSheet(dateRange[0],dateRange[1])
           }
        })
    }

    // Customizable Area End
}
